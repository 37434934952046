import Link from "next/link";
import { PlusIcon } from "@heroicons/react/24/solid";
import * as Analytics from "../utils/analytics";
import ReactPlayer from "react-player";
import { isNil } from "ramda";

export default function Empty({
  primary,
  secondary,
  icon,
  cta,
  href,
  onClick,
  videoUrl,
  videoWidth,
}: {
  primary: string;
  secondary: string;
  cta: string;
  href?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  videoUrl?: string;
  videoWidth?: string;
}) {
  href = href || "#";
  videoWidth = videoWidth || "60%";

  const Icon = () => {
    if (icon) {
      return icon;
    }
    return (
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
    );
  };

  return (
    <div className="text-center">
      {videoUrl && (
        <div className="flex justify-center items-center h-full mb-6">
          <div className="w-8/12">
            <ReactPlayer
              width="100%"
              url={videoUrl}
              onStart={() => {
                Analytics.track("EmptyVideoCompleted", { videoUrl, primary });
              }}
              onEnded={() => {
                Analytics.track("EmptyVideoCompleted", { videoUrl, primary });
              }}
            />
          </div>
        </div>
      )}
      {!videoUrl && <Icon />}
      <h3 className="mt-2 text-sm font-medium text-gray-900">{primary}</h3>
      <p className="mt-1 text-sm text-gray-500">{secondary}</p>
      <div className="mt-6">
        <Link href={href} type="button">
          <button
            onClick={(e) => {
              Analytics.track("EmptyClick", { cta });
              if (onClick) {
                e.preventDefault();
                onClick();
              }
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {cta}
          </button>
        </Link>
      </div>
    </div>
  );
}
