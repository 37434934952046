import { useState, useEffect } from "react";
import { dayjs } from "./utils";
import Modal, { useModal } from "../components/Modal";
import * as R from "ramda";
import Empty from "./Empty";
import { List, UPDATE_LIST_MUTATION, GET_LISTS } from "../utils/graphql";
import { classNames } from "../utils";
import Link from "next/link";
import { useQuery, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import * as Analytics from "../utils/analytics";
import { Dialog, Transition } from "@headlessui/react";
import LoadingIcon from "./loading-icon";

interface IActionButton {
  primary: string;
  onClick: (listId: string) => void;
  disabled?: boolean;
}

const CreateModal = () => {
  const [_, setOpen] = useModal();
  const [updateListMutation, { loading }] = useMutation(UPDATE_LIST_MUTATION);
  const [label, setLabel] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    Analytics.track("AddListsViewed");
  }, []);

  const createList = async () => {
    if (R.isEmpty(label) || loading) {
      return;
    }
    const res = await updateListMutation({ variables: { label } });

    const { id } = res.data?.updateList;
    Analytics.track("ListCreated");
    router.push("/list/" + id);
  };

  return (
    <>
      <div>
        <div className="text-center">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Create New List
          </Dialog.Title>
          <div className="mt-2">
            <div className="text-left rounded-md px-3 pt-2.5 pb-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label
                htmlFor="name"
                className="block text-xs font-medium text-gray-900"
              >
                List Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Los Angeles Agencies"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          disabled={loading}
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={(e) => {
            e.preventDefault();
            createList();
          }}
        >
          <LoadingIcon loading={loading} />
          Create
        </button>
        <button
          type="button"
          disabled={loading}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

const ListsWrapper = ({
  lists,
  onNewClick,
  actionButton,
}: {
  lists: List[];
  onNewClick?: () => void;
  actionButton?: IActionButton;
}) => {
  const [_, setOpen] = useModal();

  return (
    <ListsComponent
      lists={lists}
      actionButton={actionButton}
      onNewClick={() => {
        if (!onNewClick) {
          setOpen(true);
          return;
        }
        onNewClick();
      }}
    />
  );
};

export const ListsWithControls = ({
  lists,
  onNewClick,
  actionButton,
}: {
  lists: List[];
  onNewClick?: () => void;
  actionButton?: IActionButton;
}) => {
  return (
    <>
      <Modal>
        <CreateModal />
      </Modal>
      <ListsWrapper
        lists={lists}
        actionButton={actionButton}
        onNewClick={onNewClick}
      />
    </>
  );
};

export const useLists = () => {
  const { data, loading } = useQuery(GET_LISTS, {
    fetchPolicy: "network-only",
  });
  const lists: List[] = data?.lists || [];
  return { lists, loading };
};

export default function ListsComponent({
  lists,
  onNewClick,
  actionButton,
}: {
  lists: List[];
  onNewClick?: () => void;
  actionButton?: IActionButton;
}) {
  return (
    <>
      {R.isNil(lists) || R.isEmpty(lists) ? (
        <Empty
          primary="No Lists Yet"
          secondary="Get started by creating a new list."
          cta="Create A List"
          href="#"
          onClick={() => {
            if (onNewClick) {
              onNewClick();
            }
          }}
        />
      ) : (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">&nbsp;</div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  if (onNewClick) {
                    onNewClick();
                  }
                }}
              >
                New List
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Total Contacts
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Created
                      </th>
                      {actionButton && (
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {lists.map((list, idx) => (
                      <tr key={idx}>
                        <td
                          className={classNames(
                            idx !== lists.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <Link href={"/list/" + list.id}>{list.label}</Link>
                        </td>
                        <td
                          className={classNames(
                            idx !== lists.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                          )}
                        >
                          {list.contactsCount}
                        </td>
                        <td
                          className={classNames(
                            idx !== lists.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
                          )}
                        >
                          {dayjs().to(dayjs(list.createdAt))}
                        </td>
                        {actionButton && (
                          <td
                            className={classNames(
                              idx !== lists.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                            )}
                          >
                            <button
                              disabled={actionButton.disabled}
                              type="button"
                              className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => actionButton.onClick(list.id)}
                            >
                              {actionButton.primary}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
